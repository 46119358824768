const environments = {
    local:{
        server: "http://127.0.0.1:9090/api",
        token: "1234",
    },
    staging:{
        server: "https://staging-api-heineken-m24ea6mwca-ue.a.run.app/api",
        token: "1234",
    },
    prod:{
        server: "https://api-heineken-m24ea6mwca-ue.a.run.app/api",
        token: "1234",
    }
}

export default environments.prod;