import 'assets/fonts/HEINEKEN Core.ttf';
import 'assets/fonts/HEINEKEN Core-Thin.ttf';
import 'assets/fonts/HEINEKEN Core-Italic.ttf';
import 'assets/fonts/HEINEKEN Core-Bold.ttf';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './main';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, IconButton, ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { setupInterceptors } from 'config/http-service';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import { CloseOutlined as CloseIcon } from '@mui/icons-material';

setupInterceptors();

const theme = createTheme({
	palette: {
		primary: {
			main: '#205527',
		},
		secondary: {
			main: '#A7E833',
		},
	},
	typography: {
		fontFamily: 'HEINEKEN Core',
	},
	components: {
		MuiTextField: {
			defaultProps: {
				fullWidth: true,
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontWeight: '700',
				},
				asterisk: {
					color: 'red',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					'& legend': {
						fontSize: '0.85em',
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				fullWidth: true,
			},
			styleOverrides: {
				root: {
					fontWeight: 700,
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				'root': {
					'&.Mui-selected': {
						'opacity': 1,
					},
				},
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: {
					fontWeight: 700,
				},
			},
		},
	},
});

const CloseButton = (props: { key: SnackbarKey }) => (
	<IconButton aria-label="Close notification" color="inherit" onClick={() => closeSnackbar(props.key)}>
		<CloseIcon fontSize="small" />
	</IconButton>
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
				<SnackbarProvider
					action={(key) => <CloseButton key={key} />}
					maxSnack={3}
					autoHideDuration={3000}
					anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
					dense
					preventDuplicate>
					<App />
				</SnackbarProvider>
			</LocalizationProvider>
		</ThemeProvider>
	</React.StrictMode>
);

reportWebVitals();
