import axios from 'axios';
import env from 'environment';

const setupInterceptors = () => {
	axios.defaults.baseURL = env.server;
	axios.defaults.responseType = 'json';
	axios.defaults.headers['Accept'] = '*/*';
	axios.defaults.headers['Content-Type'] = 'application/json';

	axios.interceptors.request.use(
		(config) => {
			config.headers.Authorization = `Bearer ${env.token}`;
			if (config.data === undefined) {
				config.data = null;
			}
			return config;
		},
		(error) => Promise.reject(error)
	);

	axios.interceptors.response.use(
		(response) => {
			if (response.data) {
				return response;
			} else {
				throw response;
			}
		},
		(error) => {
			return Promise.reject(error);
		}
	);
};

export { setupInterceptors };
