import React from 'react';
import { Box } from '@mui/material';

export type SideProps = {
	image: string;
};

const Side = (props: SideProps) => {
	return (
		<Box
			sx={{
				height: '100%',
				backgroundImage: `url(${props.image})`,
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center',
				backgroundPositionY: 0,
			}}></Box>
	);
};

export default Side;
