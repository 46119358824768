import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Stack, TextField, Typography } from '@mui/material';
import { Controller, DatePickerElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import InputMask from 'react-input-mask';
import { constants } from 'config/constants';
import { useMainContext } from 'main';
import useHttp, { ErrorHttp } from 'hooks/use-http';
import { format } from 'date-fns';
import { Powered, Heineken } from 'components';
import { CompleteData, RegisterData } from 'config/types';
import { enqueueSnackbar } from 'notistack';

export const initialData: RegisterData = {
    document: '',
    code: '',
    birthdate: undefined,
};

const ValidateRegister = () => {
    const context = useMainContext();
    const http = useHttp();
    const [error, setError] = React.useState<ErrorHttp | undefined>();
    const formContext = useForm({
        defaultValues: initialData,
    });

    const onSubmit = async (values: RegisterData) => {
        const body = {
            ...values,
            document: values.document.replace(/[^\d]/g, ''),
            birthdate: format(values.birthdate || new Date(), 'yyyy-MM-dd'),
        };
        http.call('/register', 'POST', body)
            .then((data) => {
                console.log(data);
                context.setUser(Object.assign({}, { ...body, name: data.name }) as CompleteData);
                context.setStep(1);
            })
            .catch((error: ErrorHttp) => {
                if (error.status === 404) {
                    setError({
                        title: 'Ops!',
                        message:
                            'Infelizmente não localizamos você. Procure a Central de Atendimento Alymente. Fone: +55 (11) 4200-0368.',
                    });
                } else {
                    enqueueSnackbar({ message: error.message, variant: 'error' });
                }
            });
    };

    return (
        <FormContainer formContext={formContext} onSuccess={onSubmit}>
            <Stack spacing={3}>
                <Heineken />
                <Typography variant="h5" fontWeight={'bold'} color={'primary.main'}>
                    É hora de cadastrar seus dados para receber seu cartão Alymente e garantir o seu presente de final
                    de ano da HEINEKEN!
                </Typography>
                <Typography variant="h5" fontWeight={'bold'} color={'primary.main'}>
                    Preencha as informações abaixo para darmos continuidade ao seu cadastro e encaminharmos o seu
                    cartão:
                </Typography>
                {/* <Alert severity="error">O programa de pré-cadastro foi finalizado.</Alert> */}
                <TextFieldElement
                    required
                    validation={{ required: constants.required }}
                    name="code"
                    label="Matrícula HEINEKEN"
                />
                <Controller
                    name="document"
                    control={formContext.control}
                    rules={{ required: true }}
                    render={(e) => (
                        <InputMask mask="999.999.999-99" value={e.field.value} onChange={e.field.onChange}>
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    error={e.fieldState.error}
                                    helperText={e.fieldState.error ? constants.required : ''}
                                    required
                                    label="CPF"
                                    variant="outlined"
                                />
                            )}
                        </InputMask>
                    )}
                />
                <DatePickerElement
                    required
                    validation={{ required: constants.required }}
                    label="Data de Nascimento"
                    name="birthdate"
                />
                {error && (
                    <Alert severity="error">
                        <AlertTitle>{error.title}</AlertTitle>
                        {error.message}
                    </Alert>
                )}
                <LoadingButton loading={http.loading} type="submit" variant="contained" size="large">
                    Validar
                </LoadingButton>
                <Powered />
            </Stack>
        </FormContainer>
    );
};

export default ValidateRegister;
