import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Stack, TextField, Typography } from '@mui/material';
import { Controller, FieldError, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import InputMask from 'react-input-mask';
import { useMainContext } from 'main';
import { constants } from 'config/constants';
import { Powered, Heineken } from 'components';
import { CompleteData, CompleteRegisterData } from 'config/types';
import useHttp, { ErrorHttp } from 'hooks/use-http';
import { enqueueSnackbar } from 'notistack';

export const basicDataInitial: CompleteRegisterData = {
	phone: '',
	email: '',
};

const CompleteRegister = () => {
	const context = useMainContext();
	const http = useHttp();
	const formContext = useForm({
		defaultValues: basicDataInitial,
	});
	const watch = formContext.watch();

	// const onSubmitSms = (values: CompleteRegisterData) => {
	// 	onSubmit(values, 'sms');
	// };

	// const onSubmitEmail = (values: CompleteRegisterData) => {
	// 	onSubmit(values, 'email');
	// };

	const onSubmit = (values: CompleteRegisterData) => {
		const body = {
			...context.user,
			...values,
			phone: values.phone.replace(/[^\d]/g, ''),
		};
		http
			.call('/complete', 'POST', body)
			.then(() => {
				context.setUser(Object.assign({}, { ...body, formattedPhone: values.phone }) as CompleteData);
				context.setStep(2);
			})
			.catch((error: ErrorHttp) => enqueueSnackbar({ message: error.message, variant: 'error' }));
	};

	const validateEmail = (error: FieldError): string => {
		if (error.type === 'required') {
			return error.message || constants.required;
		}
		if (error.type === 'pattern') {
			return 'Informe um e-mail válido';
		}
		return '';
	};

	React.useEffect(() => {
		if (context.user?.email) {
			formContext.setValue('email', context.user.email);
		}
		if (context.user?.phone) {
			formContext.setValue('phone', context.user.phone);
		}
	}, [context.user, formContext]);

	return (
		<FormContainer formContext={formContext}>
			<Stack spacing={3}>
				<Heineken />
				<Typography variant="h5" fontWeight={'bold'} color={'primary.main'}>
					{`Ótimo, localizamos você ${context.user?.name}. Agora informe um dos seguintes dados para validação`}
				</Typography>
				<TextFieldElement
					required={!watch.phone}
					validation={{ required: !watch.phone ? constants.required : undefined }}
					name="email"
					label="Seu melhor e-mail"
					type="email"
					parseError={validateEmail}
				/>
				<Controller
					name="phone"
					control={formContext.control}
					rules={{ required: !watch.email ? constants.required : undefined }}
					render={(e) => (
						<InputMask mask="(99) 99999-9999" value={e.field.value} onChange={e.field.onChange}>
							{(inputProps) => (
								<TextField
									{...inputProps}
									required={!watch.email}
									error={e.fieldState.error}
									helperText={e.fieldState.error ? constants.required : ''}
									label="Seu número de celular"
									variant="outlined"
								/>
							)}
						</InputMask>
					)}
				/>
				<Typography variant="h6" fontWeight={'bold'} color={'primary.main'}>
					Você receberá um código de validação no meio informado acima. Se informou os dois, receberá dois códigos para
					validação.
				</Typography>
				{http.error && (
					<Alert severity="error">
						<AlertTitle>{http.error.title}</AlertTitle>
						{http.error.message}
					</Alert>
				)}
				<LoadingButton
					loading={http.loading}
					variant="contained"
					size="large"
					onClick={formContext.handleSubmit(onSubmit)}>
					Enviar códigos
				</LoadingButton>
				{/* <LoadingButton
					loading={http.loading}
					variant="contained"
					size="large"
					onClick={formContext.handleSubmit(onSubmitSms)}>
					Enviar código via SMS
				</LoadingButton>
				<LoadingButton
					loading={http.loading}
					variant="contained"
					size="large"
					onClick={formContext.handleSubmit(onSubmitEmail)}>
					Enviar código via E-Mail
				</LoadingButton> */}
				<Powered />
			</Stack>
		</FormContainer>
	);
};

export default CompleteRegister;
