import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, Stack, Typography } from '@mui/material';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { useMainContext } from 'main';
import { Powered, Heineken } from 'components';
import useHttp, { ErrorHttp } from 'hooks/use-http';
import { CompleteData, TokenData } from 'config/types';
import { constants } from 'config/constants';
import { enqueueSnackbar } from 'notistack';

export const basicDataInitial: TokenData = {
	tokenEmail: '',
	tokenPhone: '',
};

const VerifyToken = () => {
	const context = useMainContext();
	const http = useHttp();
	const [error, setError] = React.useState<ErrorHttp | undefined>();
	const [resend, setResend] = React.useState(false);
	const formContext = useForm({
		defaultValues: basicDataInitial,
	});
	const watch = formContext.watch();

	const onSubmit = (values: TokenData) => {
		setError(undefined);
		const body = {
			...context.user,
			...values,
		};
		http
			.call('/verify', 'POST', body)
			.then((data) => {
				let message = '';
				const bothToken = values.tokenEmail.length > 0 && values.tokenPhone.length > 0;
				const invalidToken = data.isTokenValidEmail === false && data.isTokenValidPhone === false;
				if (bothToken && invalidToken) {
					message = 'Os dois códigos informados estão inválidos. Pode digitar novamente?';
				} else if (values.tokenEmail.length > 0 && data.isTokenValidEmail === false) {
					message = 'O código informado do e-mail está inválido. Pode digitar novamente?';
				} else if (values.tokenPhone.length > 0 && data.isTokenValidPhone === false) {
					message = 'O código informado do celular está inválido. Pode digitar novamente?';
				}

				if (message.length > 0) {
					setError({
						title: 'Erro na validação!',
						message: message,
					});
				} else {
					context.setUser(Object.assign({}, body) as CompleteData);
					context.setStep(3);
				}
			})
			.catch((error: ErrorHttp) => enqueueSnackbar({ message: error.message, variant: 'error' }));
	};

	React.useEffect(() => {
		setTimeout(() => {
			setResend(true);
		}, 10000);
	}, []);

	return (
		<FormContainer formContext={formContext} onSuccess={onSubmit}>
			<Stack spacing={3}>
				<Heineken />
				<Typography variant="h5" fontWeight={'bold'} color={'primary.main'}>
					Recebeu um e-mail ou um SMS? Informe o código recebido abaixo:
				</Typography>
				{context.user?.email && (
					<Box>
						<Typography
							sx={{ mb: 1 }}
							color={'primary.main'}>{`Enviado para o e-mail: ${context.user?.email}`}</Typography>
						<TextFieldElement
							required={!watch.tokenPhone}
							validation={{ required: !watch.tokenPhone ? constants.required : undefined }}
							name="tokenEmail"
							label="Código recebido no e-mail"
							inputProps={{ maxLength: 6 }}
						/>
					</Box>
				)}
				{context.user?.phone && (
					<Box>
						<Typography
							sx={{ mb: 1 }}
							color={'primary.main'}>{`Enviado para o celular: ${context.user.formattedPhone}`}</Typography>
						<TextFieldElement
							required={!watch.tokenEmail}
							validation={{ required: !watch.tokenEmail ? constants.required : undefined }}
							name="tokenPhone"
							label="Código recebido no celular"
							inputProps={{ maxLength: 6 }}
						/>
					</Box>
				)}
				{(http.error || error) && (
					<Alert severity="error">
						<AlertTitle>{http.error?.title || error?.title}</AlertTitle>
						{http.error?.message || error?.message}
					</Alert>
				)}
				<LoadingButton loading={http.loading} type="submit" variant="contained" size="large">
					Confirmar
				</LoadingButton>
				<Button disabled={!resend} variant="text" size="large" onClick={() => context.setStep(1)}>
					Reenviar
				</Button>
				<Powered />
			</Stack>
		</FormContainer>
	);
};

export default VerifyToken;
