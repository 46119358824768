import { Typography, Stack } from '@mui/material';
import { Heineken, Powered } from 'components';
import SuccessImage from 'assets/img/Asset_Heineken_02.png';

const Success = () => {
	return (
		<Stack
			spacing={10}
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			textAlign={'center'}
			width={'100%'}
			sx={{
				backgroundImage: `url(${SuccessImage})`,
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}>
			<Heineken />
			<Typography variant="h2" fontWeight={'bold'} color="secondary.main">
				Dados Enviados!
			</Typography>
			<Typography variant="h5" fontWeight={'bold'} color="primary.main">
				Agora é só aguardar a entrega do seu Alymente.
			</Typography>
			<Powered />
		</Stack>
	);
};

export default Success;
