import { Typography, Box } from '@mui/material';
import Alymente from 'assets/img/logo2.svg';

const Powered = () => {
	return (
		<Box textAlign={'center'}>
			<img src={Alymente} alt="alymente" width={100} />
			<Typography color={'text.secondary'} variant="caption" component="h6">
				Powered by Alymente
			</Typography>
		</Box>
	);
};

export default Powered;
