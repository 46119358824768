import axios from 'axios';
import { CepGeo, StateGeo } from 'config/types';
import React from 'react';

const states: StateGeo[] = [
	{ id: 12, uf: 'AC', name: 'Acre' },
	{ id: 27, uf: 'AL', name: 'Alagoas' },
	{ id: 16, uf: 'AP', name: 'Amapá' },
	{ id: 13, uf: 'AM', name: 'Amazonas' },
	{ id: 29, uf: 'BA', name: 'Bahia' },
	{ id: 23, uf: 'CE', name: 'Ceará' },
	{ id: 53, uf: 'DF', name: 'Distrito Federal' },
	{ id: 32, uf: 'ES', name: 'Espírito Santo' },
	{ id: 52, uf: 'GO', name: 'Goiás' },
	{ id: 21, uf: 'MA', name: 'Maranhão' },
	{ id: 51, uf: 'MT', name: 'Mato Grosso' },
	{ id: 50, uf: 'MS', name: 'Mato Grosso do Sul' },
	{ id: 31, uf: 'MG', name: 'Minas Gerais' },
	{ id: 15, uf: 'PA', name: 'Pará' },
	{ id: 25, uf: 'PB', name: 'Paraíba' },
	{ id: 41, uf: 'PR', name: 'Paraná' },
	{ id: 26, uf: 'PE', name: 'Pernambuco' },
	{ id: 22, uf: 'PI', name: 'Piauí' },
	{ id: 33, uf: 'RJ', name: 'Rio de Janeiro' },
	{ id: 24, uf: 'RN', name: 'Rio Grande do Norte' },
	{ id: 43, uf: 'RS', name: 'Rio Grande do Sul' },
	{ id: 11, uf: 'RO', name: 'Rondônia' },
	{ id: 14, uf: 'RR', name: 'Roraima' },
	{ id: 42, uf: 'SC', name: 'Santa Catarina' },
	{ id: 35, uf: 'SP', name: 'São Paulo' },
	{ id: 28, uf: 'SE', name: 'Sergipe' },
	{ id: 17, uf: 'TO', name: 'Tocantins' },
];

const useHttpGeo = () => {
	const [loading, setLoading] = React.useState(false);
	const api = axios.create({
		baseURL: 'https://viacep.com.br/ws',
	});

	const getCep = async (cep: string): Promise<CepGeo> => {
		setLoading(true);
		try {
			cep = cep.replace(/[^\d]/g, '');
			const { data } = await api.get<CepGeo>(`/${cep}/json`);
			return data;
		} catch (ex) {
			throw new Error('Ocorreu um erro na leitura do cep.');
		} finally {
			setLoading(false);
		}
	};

	return { loading, getCep, states };
};

export default useHttpGeo;
