import { Box } from '@mui/material';
import HeinekenImg from 'assets/img/heineken.svg';

const Heineken = () => {
	return (
		<Box sx={{ width: '100%', textAlign: 'center' }}>
			<img alt="heineken" src={HeinekenImg} style={{ width: 200 }} />
		</Box>
	);
};

export default Heineken;
