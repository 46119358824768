import axios from 'axios';
import React from 'react';

export type ErrorHttp = {
	status?: number;
	title: string;
	message: string;
};
const useHttp = () => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<ErrorHttp | undefined>();

	const call = async (url: string, method: 'POST' | 'GET', body?: any | undefined) => {
		return new Promise<any>(async (resolve, reject) => {
			setLoading(true);
			try {
				const { data } = await axios({
					url: url,
					method: method,
					data: body,
				});
				resolve(data);
			} catch (ex: any) {
				const _error: ErrorHttp = {
					status: ex.response?.status || 500,
					title: 'Ops!',
					message: 'Ocorreu um erro no processamento. Pode tentar novamente?',
				};
				setError(_error);
				reject(_error);
			} finally {
				setLoading(false);
			}
		});
	};

	return { loading, call,error };
};

export default useHttp;
