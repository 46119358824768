import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { CompleteData } from 'config/types';
import { Side } from 'components';

import ValidateRegister from './views/validate-register';
import CompleteRegister from './views/complete-register';
import VerifyToken from './views/verify-token';
import Address from './views/address';
import Success from './views/success';

import Step1 from 'assets/img/Asset_Heineken_04.png';
import Step from 'assets/img/step.png';
import { Theme, useMediaQuery } from '@mui/material';

const MainContext = React.createContext<{
	setStep: (value: number) => void;
	setUser: (value: CompleteData) => void;
	user: CompleteData | undefined;
}>({
	setStep: () => null,
	setUser: () => undefined,
	user: undefined,
});
export const useMainContext = () => React.useContext(MainContext);

type ContainerProps = {
	image: string;
	order?: number[];
	children: JSX.Element | JSX.Element[];
};

const Container = (props: ContainerProps) => {
	const { order = [0, 1] } = props;
	return (
		<>
			<Grid
				order={order[0]}
				xl={7}
				md={6}
				sm={12}
				sx={{
					position: 'relative',
					overflow: 'hidden',
					flex: 4,
					backgroundColor: '#5ACD8B',
					minHeight: '600px',
				}}>
				<Side image={props.image} />
			</Grid>
			<Grid
				order={order[1]}
				container
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
				xl={5}
				lg={6}
				md={6}
				sm={12}>
				<Grid xl={8} lg={9} sm={10} sx={{ p: 3 }}>
					{props.children}
				</Grid>
			</Grid>
		</>
	);
};

function App() {
	const [step, setStep] = React.useState(0);
	const [user, setUser] = React.useState<CompleteData | undefined>();
	const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	return (
		<Grid container sx={{ minHeight: '100vh' }}>
			<MainContext.Provider value={{ setStep, setUser, user }}>
				{step === 0 && (
					<Container image={Step1} order={matches ? [1, 0] : [0, 1]}>
						<ValidateRegister />
					</Container>
				)}
				{step === 1 && (
					<Container image={Step} order={[1, 0]}>
						<CompleteRegister />
					</Container>
				)}
				{step === 2 && (
					<Container image={Step} order={[1, 0]}>
						<VerifyToken />
					</Container>
				)}
				{step === 3 && (
					<Container image={Step} order={[1, 0]}>
						<Address />
					</Container>
				)}
				{step === 4 && <Success />}
			</MainContext.Provider>
		</Grid>
	);
}

export default App;
