import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, TextField, Typography } from '@mui/material';
import { Controller, FormContainer, SelectElement, TextFieldElement, useForm } from 'react-hook-form-mui';
import InputMask from 'react-input-mask';
import { useMainContext } from 'main';
import { constants } from 'config/constants';
import { Powered, Heineken } from 'components';
import { AddressData, CompleteData } from 'config/types';
import useHttp from 'hooks/use-http';
import useHttpGeo from 'hooks/use-http-geo';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { enqueueSnackbar } from 'notistack';

export const basicDataInitial: AddressData = {
	zipcode: '',
	street: '',
	streetNumber: '',
	city: '',
	complement: '',
	neighborhood: '',
	state: '',
};

const Address = () => {
	const context = useMainContext();
	const http = useHttp();
	const httpGeo = useHttpGeo();
	const formContext = useForm({
		defaultValues: basicDataInitial,
	});

	const onSubmit = (values: AddressData) => {
		const body = {
			...context.user,
			...values,
			complement: values.complement.trim(),
			zipcode: values.zipcode.replace(/[^\d]/g, ''),
		};
		http.call('/address', 'POST', body).then(() => {
			context.setUser(Object.assign({}, body) as CompleteData);
			context.setStep(4);
		});
	};

	const loadCep = async (cep) => {
		try {
			const data = await httpGeo.getCep(cep);
			formContext.setValue('city', data.localidade);
			formContext.setValue('neighborhood', data.bairro);
			formContext.setValue('state', data.uf);
			formContext.setValue('street', data.logradouro);
		} catch (ex) {
			enqueueSnackbar({ message: ex as string, variant: 'error' });
		}
	};

	return (
		<FormContainer formContext={formContext}>
			<Grid container rowSpacing={3} spacing={2}>
				<Grid xs={12}>
					<Heineken />
				</Grid>
				<Grid xs={12}>
					<Typography variant="h5" fontWeight={'bold'} color={'primary.main'}>
						Agora vamos cadastrar seu endereço?
					</Typography>
				</Grid>
				<Grid sm={4} xs={12}>
					<Controller
						name="zipcode"
						control={formContext.control}
						rules={{ required: constants.required }}
						render={(e) => (
							<InputMask
								mask="99999-999"
								value={e.field.value}
								onChange={e.field.onChange}
								onBlur={() => loadCep(e.field.value)}>
								{(inputProps) => (
									<TextField
										{...inputProps}
										required
										error={e.fieldState.error}
										helperText={e.fieldState.error ? constants.required : ''}
										label="CEP"
										variant="outlined"
									/>
								)}
							</InputMask>
						)}
					/>
				</Grid>
				<Grid sm={8} xs={12}>
					<TextFieldElement required validation={{ required: constants.required }} name="street" label="Endereço" />
				</Grid>
				<Grid md={4} sm={4} xs={12}>
					<TextFieldElement required validation={{ required: constants.required }} name="streetNumber" label="Número" />
				</Grid>
				<Grid md={8} sm={4} xs={12}>
					<TextFieldElement name="complement" label="Complemento" />
				</Grid>
				<Grid md={6} sm={4} xs={12}>
					<TextFieldElement required validation={{ required: constants.required }} name="neighborhood" label="Bairro" />
				</Grid>
				<Grid sm={6} xs={12}>
					<SelectElement
						required
						validation={{ required: constants.required }}
						name="state"
						label="UF"
						options={httpGeo.states}
						valueKey="uf"
						labelKey="name"
					/>
				</Grid>
				<Grid md={12} sm={6} xs={12}>
					<TextFieldElement required validation={{ required: constants.required }} name="city" label="Cidade" />
				</Grid>
				<Grid xs={12}>
					<Typography variant="h6" fontWeight={'bold'} color={'primary.main'}>
						Uma pessoa deverá estar presente neste endereço para receber a carta.
					</Typography>
				</Grid>

				{http.error && (
					<Grid xs={12}>
						<Alert severity="error">
							<AlertTitle>{http.error.title}</AlertTitle>
							{http.error.message}
						</Alert>
					</Grid>
				)}
				<Grid xs={12}>
					<LoadingButton
						loading={http.loading}
						variant="contained"
						size="large"
						onClick={formContext.handleSubmit(onSubmit)}>
						Salvar endereço
					</LoadingButton>
				</Grid>
				<Grid xs={12}>
					<Powered />
				</Grid>
			</Grid>
		</FormContainer>
	);
};

export default Address;
